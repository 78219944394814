.about-section {
  padding: 1px 100px;
  background-color: #f4f4f4;
}
.aboutHead{
  padding: 115px 20px;
  background: url('../Image/main.jpg') no-repeat center center;
  background-size: cover;
  text-align: center;
}
.aboutHead h1 {
  font-size: 2rem;
  margin-bottom: 20px;
  font-weight: bold;
  color: white;
  /* font-family: "Montserrat", sans-serif; */
  margin: 0px;
  padding: 20px;
}

.about-section .container {
  max-width: 100%;
  margin: 0 auto;
}

.about-section h2 {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #333;
  text-align: justify;
}

.about-section h3 {
  font-size: 1.75rem;
  margin-top: 30px;
  margin-bottom: 15px;
  color: #444;
  text-align: justify;
}

.about-section h4 {
  font-size: 1.5rem;
  margin-top: 20px;
  margin-bottom: 10px;
  color: #555;
  text-align: justify;
}

.about-section p {
  font-size: 1rem;
  line-height: 1.6;
  color: #666;
  text-align: justify;
}

.about-section ul {
  list-style-type: disc;
  margin: 20px 0;
  padding-left: 20px;
  text-align: justify;
}

.about-section li {
  margin-bottom: 10px;
  font-size: 1rem;
  color: #666;
  text-align: justify;
}

.about-section strong {
  font-weight: bold;
}
@media (max-width: 768px) {
  .about-section {
    padding: 1px 20px;
    background-color: #f4f4f4;
  }
}