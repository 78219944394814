/* src/css/servicesHome.css */
.services-home {
    padding: 40px 100px;
    background-color: #f9f9f9;
}

.services-home .container {
    max-width: 1200px;
    margin: 0 auto;
   
}

.servicesHome-content {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.heading-container {
    display: flex;
    align-items: center;
    flex: 1;
}

.servicesHome-heading {
    padding-right: 20px;
}

.servicesHome-heading h1 {
    font-size: 2rem;
    color: #333;
    /* font-family: "Montserrat", sans-serif; */
    margin: 0;
    text-align: justify;
}

.vertical-line {
    width: 2px;
    height: 100px;
    background-color: #ff6600;
    margin: 0 20px;
}

.content {
    flex: 2;
}

.summary {
    font-size: 1rem;
    color: #666;
    margin: 0;
    line-height: 1.6;
    text-align: justify;
}

.button-container {
    text-align: center;
    margin-top: 20px;
}

.view-more-button {
    display: inline-block;
    padding: 10px 20px;
    font-size: 1rem;
    color: white;
    background-color: #ff6600;
    border: none;
    cursor: pointer;
    text-decoration: none;
    margin-top: 20px;
    transition: background-color 0.3s, transform 0.2s;
}

.view-more-button:hover {
    background-color: #7e280d;
    transform: scale(1.05); 
}

/* New section for columns */
.servicesHome-columns {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px; /* Space between columns */
    margin-top: 40px; /* Space above columns */
}
.servicesHome-image {
    width: 100%;
    height: 206px;
    object-fit: cover;
}

.column h3 {
    font-size: 1rem;
    color: #333;
    margin: 10px 0;
    text-align: justify;
}

.divider {
    width: 100%;
    height: 2px;
    background-color: #ff6600;
    margin: 10px auto;
    border: none;
}

.column p {
    font-size: 0.7rem;
    color: #666;
    margin: 10px 0;
    text-align: justify;
}

.read-more-button {
    color: #ff6600;
    text-decoration: none;
    font-weight: bold;
    font-size: 0.7rem;
    text-align: justify;
}

.read-more-button:hover {
    color: #170a01;
    text-decoration: underline;
}
.column {
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for hover effects */
    padding: 10px;
    border-radius: 8px; /* Optional: rounded corners for better aesthetics */
}

/* .column:hover
    transform: scale(1.10);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); 
    background-color: #fff; 
 */

.column:hover {
    transform: scale(1.10); /* Slightly scale up the column */
}
/* Responsive styles */
@media (max-width: 992px) {
    .services-home {
        padding: 40px 100px;
        background-color: #f9f9f9;
    }
    .heading-container {
        flex-direction: row;
        padding: 0 50px;
    }

    .servicesHome-heading {
        padding-right: 0;
        padding-bottom: 10px;
    }

    .vertical-line {
        height: 120px;
        margin: 0px 20px;
    }

    .view-more-button {
        padding: 8px 16px;
        font-size: 0.9rem;
    }
    .servicesHome-columns {
        grid-template-columns: 1fr; /* Single column on very small screens */
    }
    .servicesHome-image {
        height: 450px;
    }
}

@media (max-width: 768px) {
    .servicesHome-image {
        height:  180px;
    }

    .services-home {
        padding: 40px 20px;
        background-color: #f9f9f9;
    }
    .servicesHome-content {
        flex-direction: column;
        text-align: center;
    }

    .heading-container {
        flex-direction: column;
        padding: 0 50px;
    }

    .vertical-line {
        height: 0px;
    }

    .servicesHome-heading h1 {
        font-size: 1.3rem;
    }

    .summary {
        font-size: 0.6rem;
    }

    .view-more-button {
        padding: 8px 16px;
        font-size: 0.575rem;
    }

    .servicesHome-columns {
        grid-template-columns: 1fr; /* Single column on very small screens */
    }
}

@media (max-width: 480px) {
    .services-home .container {
        padding: 0 0px;
    }
}
