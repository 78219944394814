
.services-section {
  padding: 40px 100px;
  background-color: #f4f4f4;
}

.servicesHead {
  padding: 115px 20px;
  background: url('../Image/main.jpg') no-repeat center center/cover;
  text-align: center;
}

.servicesHead h1 {
  font-size: 2.5rem;
  color: white;
  /* font-family: "Montserrat", sans-serif; */
  padding: 20px;
  margin: 0;
}

/* Container to center content and limit max-width */
.services-section .container {
  max-width: 1200px;
  margin: 0 auto;
}

.services-section .container p{
  font-size: 1rem;
  color: #666;
  margin: 0 0 15px;
  text-align: justify;
}


/* Flexbox layout for service items */
.services-content {
  display: flex;
  flex-direction: column;
  gap: 40px; /* Space between service items */
}

.service-item {
  display: flex;
  flex-wrap: wrap; /* Allows text to wrap around the image */
  align-items: flex-start; /* Align items to the start */
  gap: 20px; /* Space between image and text */
}

.service-image {
  flex: 1;
  max-width: 40%; /* Image takes up to 40% of the width */
  height: auto;
  object-fit: cover;
  margin-top: 20.8px;
}

.service-text {
  flex: 2;
  text-align: justify;
}

.service-text h3 {
  font-size: 1.3rem;
  color: #333;
  margin-bottom: 10px;
}

.service-text p {
  font-size: 1rem;
  color: #666;
  margin: 0 0 15px;
}

.service-text ul {
  margin: 10px 0;
  padding-left: 20px;
}

.service-text li {
  font-size: 1rem;
  color: #666;
}

/* Get in touch section */
.get-in-touch {
  margin-top: 30px;
  text-align: center;
}

.get-in-touch h3 {
  font-size: 2rem;
  color: #333;
  text-align: justify;
}

.get-in-touch p {
  font-size: 1.1rem;
  color: #555;
  margin: 20px 0;
  text-align: justify;
}

@media (max-width: 992px) {
  .servicesHead h1 {
      font-size: 1.75rem;
  }

  .get-in-touch h3 {
      font-size: 1.75rem;
  }

  .get-in-touch p {
      font-size: 1rem;
  }
}

@media (max-width: 768px) {

  .services-section {
    padding: 40px 20px;
}

  .servicesHead {
      padding: 40px 10px;
  }

  .servicesHead h1 {
      font-size: 1.5rem;
  }

  .get-in-touch h3 {
      font-size: 1.5rem;
  }

  .get-in-touch p {
      font-size: 0.875rem;
  }

  .service-item {
      flex-direction: column; /* Stack text and image in column on mobile */
  }

  .service-image {
      max-width: 100%; /* Image takes full width on mobile */
  }
}

@media (max-width: 480px) {
  .services-section {
      padding: 40px 20px;
  }
}
