/* src/css/contact.css */
.contact-section {
  padding:  1px 20px;
  background: url('../Image/contact-automation-happy-customers-bgs.webp') no-repeat center center/cover;
  color: #fff;
}

.contact-section .container {
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  padding-bottom: 20px;
}

.contact-section p {
  font-size: 0.8rem;
  color: #ffffff;
  margin-bottom: 15px;
}

.contact-columns {
  display: grid;
  grid-template-columns: 1fr; /* Single column layout by default */
  gap: 10px;
}

.contact-column {
  background-color: rgba(70, 70, 70, 0.62);
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.contact-column:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.contact-column h3 {
  font-size: 1.2rem;
  color: #ffffff;
  margin-bottom: 10px;
  display: inline-block;
  border-bottom: 3px solid #ff5722;
  padding-bottom: 5px;
}

.contact-column ul {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 15px;
}

.contact-column ul li {
  font-size: 1.2rem;
}

.contact-column ul li a {
  color: #ff5722;
  text-decoration: none;
  display: flex;
  align-items: center;
  font-size: 1.5rem;
}

.contact-column .location a {
  text-decoration: none;
}

.contact-column ul li a:hover {
  text-decoration: underline;
}

.contact-column ul li a svg {
  margin-right: 6px;
  color: #ff5722;
}

.location {
  margin-bottom: 10px;
}

.location h4 {
  font-size: 1rem;
  color: #ff5722;
  margin-bottom: 5px;
}

.contact-details {
  margin-top: 10px;
}

.contact-group {
  margin-bottom: 10px;
}

.contact-group h4 {
  font-size: 1rem;
  color: #ff5722;
  margin-bottom: 5px;
}

.contact-group p a.email-link {
  font-size: 0.8rem;
  color: #ffffff;
}

.contact-group svg {
  margin-right: 6px;
  color: #ff5722;
}

/* Responsive Design */
@media (min-width: 768px) {
  .contact-columns {
    grid-template-columns: repeat(2, 1fr); /* Two-column layout on tablets and up */
  }
}

@media (min-width: 1024px) {
  .contact-columns {
    grid-template-columns: repeat(3, 1fr); /* Three-column layout on desktops and up */
  }
}

@media (max-width: 480px) {
  .contact-section p {
    font-size: 0.7rem; /* Smaller font size for very small screens */
  }

  .contact-column h3 {
    font-size: 1rem; /* Smaller font size for headings on very small screens */
  }

  .contact-column ul li {
    font-size: 1rem; /* Smaller font size for icons */
  }

  .contact-column ul li a {
    font-size: 1.25rem; /* Smaller font size for links */
  }
}
